// This is a simple event emitter based on mett.js in gatsby which is based on mitt.js
// It mainly changes the data model to use a Map and Set, rather than a
// regular object and an array.

type MottHandler<EventName, Payload> = (e: Payload, eventName: EventName) => void;

export interface IMott {
  on(eventName: EventName, callback: MottHandler<EventName, Payload>): void;
  off(eventName: EventName, callback: MottHandler<EventName, Payload>): void;
  emit(eventName: EventName, e: Payload): void;
}

type EventName = string;
type Payload = any;

export function mott(): IMott {
  const mottEvents: Map<EventName, Set<MottHandler<EventName, Payload>>> = new Map();

  return {
    on(eventName: EventName, callback: MottHandler<EventName, Payload>): void {
      const set = mottEvents.get(eventName);
      if (set) {
        set.add(callback);
      } else {
        mottEvents.set(eventName, new Set([callback]));
      }
    },
    off(eventName: EventName, callback: MottHandler<EventName, Payload>): void {
      const set = mottEvents.get(eventName);
      if (set) {
        set.delete(callback);
      }
    },
    emit(eventName: EventName, e: Payload): void {
      const setName = mottEvents.get(eventName);
      if (setName) {
        setName.forEach(function mottEmitEachC(callback) {
          callback(e, eventName);
        });
      }
      const setStar = mottEvents.get(`*`);
      if (setStar) {
        setStar.forEach(function mottEmitEachStar(callback) {
          callback(e, eventName);
        });
      }
    },
  };
}

const emitter = mott();

export default emitter;
