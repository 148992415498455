import type { AppProps } from 'next/app';
import UnionApp from '@xo-union/nextjs/app';
import { useAnalytics } from '../modules/analytics/hooks/useAnalytics.hook';
import '@xo-union/tk-ui-typography/lib/globals.css';
import '@xo-union/tk-ui-links/lib/globals.css';
import '@xo-union/tk-ui-essentials/lib/reset.globals.css';
import '../styles/global.module.css';
import 'intersection-observer';
import { useRouter } from 'next/router';
import {
  DASHBOARD,
  ONBOARDING,
  ORGANIZER,
  OUR_PROFILE,
  WEDDING_VISION,
} from '../modules/analytics/constants/analytics.constants';
import { useFullStory } from '../modules/core/hooks/useFullStory';
import React, { useMemo } from 'react';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const HOMESCREEN_VIEW = 'HomescreenView';

const honeybadger = Honeybadger.configure({
  apiKey: process.env.HOMESCREEN_HONEYBADGER_API_KEY || '',
  environment: process.env.HONEYBADGER_ENV_LABEL || 'production',
  // We want report uncaught errors that occur server-side.
  enableUncaught: typeof window === 'undefined',
  // Uncomment to report errors in development:
  // reportData: true,
});

//Tell honeybadger to not report any errors from google map domain
honeybadger.beforeNotify(function (notice: any) {
  if (notice?.stack) return !/maps.googleapis.com/.test(notice.stack);
  return;
});

interface ViewModelAppProps {
  viewComponents?: Array<any>;
  viewModelData?: Array<any>;
}

function MyApp({ Component, pageProps }: AppProps<ViewModelAppProps>) {
  const [reactQueryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      })
  );

  const router = useRouter();
  const onWeddingVisionPath = useMemo(() => router.asPath.includes('my-wedding-vision'), [router]);
  const [viewComponent] = /* /dashboard */ pageProps?.viewComponents ||
    /* /onboarding/introduction */ pageProps?.viewModelData || [{}];
  const isNorthstar = viewComponent?.componentName === HOMESCREEN_VIEW;

  const data = useMemo(() => {
    return (
      /* /dashboard */ viewComponent?.meta?.globalData ||
      /* /onboarding/introduction */ viewComponent?.meta ||
      {}
    );
  }, [viewComponent]);

  const member = useMemo(() => {
    return (
      /* /dashboard */ data?.guestFederatedData?.data?.member ??
      /* /onboarding/introduction */ data?.member ??
      ''
    );
  }, [data]);

  const memberId = useMemo(() => {
    return member?.id ?? '';
  }, [member]);

  const userId = useMemo(() => {
    return member?.legacyUserId ?? '';
  }, [member]);

  const userType = useMemo(() => {
    /* Default to "new" because if this in on-boarding, the `homescreenData` isn't there, and we can just assume they are new
     * because why would they force themselves into the on-boarding flow unless they were new?
     */
    return data?.homescreenData?.userType ?? 'new';
  }, [data]);

  const wedding = useMemo(() => {
    return (
      /* /dashboard */ data?.guestFederatedData?.data?.wedding ??
      /* /onboarding/introduction */ data?.wedding ??
      {}
    );
  }, [data]);

  const wws = useMemo(() => {
    return /* /dashboard */ data?.guestFederatedData?.data?.weddingWebsite ?? {};
  }, [data]);

  const additionalIdentifyingAttributes = useMemo(() => {
    const { email, createdAt } = member;
    const { weddingDate, unconfirmedAttributes = [] } = wedding || {};
    const weddingDateConfirmed =
      !unconfirmedAttributes?.some((a: string) => a === 'wedding_date') || false;
    return {
      memberId,
      email,
      createdAt,
      weddingDate: weddingDateConfirmed ? weddingDate : null,
      wwsUrl: wws?.wwsUrl || null,
      isNorthstar,
    };
  }, [member, wedding, memberId, wws?.wwsUrl, isNorthstar]);

  useFullStory({
    memberId,
    userId,
    userType,
    enabled: !onWeddingVisionPath,
    additionalIdentifyingAttributes,
  });

  const pageAnalytics = (pagePath: string) => {
    if (pagePath.includes('my-wedding-vision')) {
      return WEDDING_VISION;
    } else if (pagePath.includes('/dashboard')) {
      return DASHBOARD;
    } else if (pagePath.includes('onboarding')) {
      return ONBOARDING;
    } else if (pagePath.includes('/our-profile')) {
      return OUR_PROFILE;
    } else {
      return ORGANIZER;
    }
  };

  useAnalytics(pageAnalytics(router.asPath));

  return (
    <UnionApp>
      <HoneybadgerErrorBoundary honeybadger={honeybadger}>
        <QueryClientProvider client={reactQueryClient}>
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} position="top-right" />
        </QueryClientProvider>
      </HoneybadgerErrorBoundary>
    </UnionApp>
  );
}

export default MyApp;
