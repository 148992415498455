export const IMPRESSION = 'impression';
export const DASHBOARD_INTERACTION = 'Dashboard Interaction';
export const NAVIGATE_ORGANIZER = 'Navigate Organizer';
export const ORGANIZER_ITEM_INTERACTION = 'Organizer Item Interaction';
export const DASHBOARD_MODULE_IMPRESSION = 'Dashboard Module Impression';
export const ORGANIZER_MODULE_IMPRESSION = 'Organizer Module Impression';
export const DASHBOARD_PRODUCT = 'northstar-dashboard';
export const ORGANIZER_PRODUCT = 'organizer';
export const TRACK_INTERACTION = 'trackInteraction';
export const TRACK_TYPEAHEAD_INTERACTION = 'trackTypeaheadInteraction';
export const TRACK_NAVIGATION = 'trackNavigation';
export const SHOWCASE_MODULE = 'showcase';
export const CLICK = 'click';
export const SCROLL = 'scroll';
export const CHANGE = 'change';
export const DASHBOARD = 'dashboard';
export const ORGANIZER = 'organizer';
export const ORGANIZER_SOURCE = 'checklist';
export const ONBOARDING = 'onboarding';
export const ONBOARDING_IMPRESSION = 'ACQ onboarding step impression';
export const ONBOARDING_INTERACTION = 'ACQ onboarding step interaction';
export const ONBOARDING_PRODUCT = 'onboarding';
export const TRACK_MODAL_JOB_COMPLETION_INTERACTION = 'trackJobCompleted';

export const WEDDING_VISION = 'weddingVision';
export const WEDDING_VISION_PRODUCT = 'wedding vision';
export const WEDDING_VISION_IMPRESSION = 'Wedding Vision Impression';

export const OUR_PROFILE = 'profile';
export const PROFILE_PRODUCT = 'onboarding settings';

export const WEDDING_VISION_VIEWED = 'Wedding Vision Viewed';
export const WEDDING_VISION_INTERACTION = 'Wedding Vision Interaction';
export const INSPIRATION_IMAGE_INTERACTION = 'Inspiration Image Interaction';
export const COLORS_EDITED = 'complete edit colors';
export const VENDOR_CLICKED = 'Click-through to Vendors';
export const PAPER_CLICKED = 'Clicked on paper';
export const WWS_CLICKED = 'Clicked on WWS';
export const FOOTER_LINK_CLICKED = 'Clicked on link in footer';
export const LIGHTBOX_VIEWED = 'view lightbox';
export const WEDDING_VISION_STYLE_UPDATED = 'Wedding Vision Style Updated';
export const CAROUSEL_VIEWED = 'Carousel Viewed';
export const CAROUSEL_VIEW_ALL = 'Carousel View All';
export const CAROUSEL_INTERACTION = 'Carousel Interaction';
export const RETAKE_QUIZ_CLICKED = 'click to retake quiz';

export const FULLSTORY_RECORD_COOKIE_NAME = 'xo-session-recording';
export const FULLSTORY_RECORD_COOKIE_EXPIRATION_DAYS = 365;

export const WELCOME_PERKS_MODAL_INTERACTION = 'Welcome Perks Modal Interaction';

export const VENDOR_SAVED = 'Add to Favorite';

export const ACCOUNT_SETTINGS_INTERACTION = 'Account Settings Interaction';

export const HERO_SECTION = 'Hero Section';
export const HERO = 'Hero';

export const MODULE_PROFILE = 'Profile';
export const PROMO_WIZARD = 'wizard';
export const PROMO_BUDGETER = 'budgeter';
