import { useEffect, useMemo, useState } from 'react';
import FlipperClient from '@xo-union/sdk-flipper';
import { setCookie } from '../../helpers/browser.helpers';
import {
  FULLSTORY_RECORD_COOKIE_EXPIRATION_DAYS,
  FULLSTORY_RECORD_COOKIE_NAME,
} from '../../../analytics/constants/analytics.constants';

interface UseFullStoryArgs {
  memberId: string;
  userId: string;
  userType?: string;
  enabled?: boolean;
  additionalIdentifyingAttributes?: Record<string, any>;
}

/**
 * This hook is meant to set a cookie on the client's browser to the value of the
 * variant of the FullStory Flipper experiment that they are bucketed into.
 *
 * The experiment id used for Flipper depends on whether the user is 'new' or 'existing',
 * which we know via the API call for the homescreen which includes the `userType` in it for this purpose.
 *
 * Note that while Flipper is being used to select a %-age of users, this isn't an experiment per say,
 * we're just leveraging the core functionality of Flipper to assign users randomly to variants based on allocations
 * that can be configured remotely.
 *
 * @param args
 */
export function useFullStory(args: UseFullStoryArgs) {
  if (args?.enabled) {
    const memberId = useMemo(() => args?.memberId ?? '', [args]);
    const userId = useMemo(() => args?.userId ?? '', [args]);
    const userType = useMemo(() => args?.userType ?? '', [args]);

    const [cookieProps, setCookieProps] = useState({
      name: '',
      value: '',
      exp: 0,
    });

    async function getFlipperAssignment(userTypeParam = '') {
      const flipperClient = new FlipperClient({
        apiKey: process.env.HOMESCREEN_FLIPPER_API_KEY,
        env: process.env.HOMESCREEN_FLIPPER_API_ENV,
      });

      const experimentId =
          userTypeParam === 'new'
              ? process.env.HOMESCREEN_FLIPPER_FULLSTORY_NEW_USERS_EXPERIMENT_ID
              : process.env.HOMESCREEN_FLIPPER_FULLSTORY_EXISTING_USERS_EXPERIMENT_ID;

      const result = await flipperClient.getAssignment({
        bucketingId: args.memberId,
        experimentId,
      });
      const assignment = await result?.response?.json();

      if (assignment?.error) {
        console.warn(`An error was reported while getting flipper assignment. ${assignment.error}`);
      }
      if (assignment?.variant_name) {
        setCookieProps({
          name: FULLSTORY_RECORD_COOKIE_NAME,
          value: assignment.variant_name,
          exp: FULLSTORY_RECORD_COOKIE_EXPIRATION_DAYS,
        });
      }
    }

    useEffect(() => {
      const enabled = process.env.HOMESCREEN_FULLSTORY_ENABLED === 'true';
      if (enabled) {
        try {
          if (memberId) {
            // Let's see if the user is bucketed (or bucket them) to be recorded or not.
            getFlipperAssignment(userType);
          }
        } catch (error) {
          /* Swallowing this error & just logging it as a warning,
           * because we don't want to have anything bad happen for the user
           * if any of this fails to execute for any reason.
           */
          console.warn('FS cookie error', error);
        }
      }
    }, [memberId, userType]);

    useEffect(() => {
      const { name, value, exp } = cookieProps || {};
      if (name && value && exp) {
        setCookie(name, value, exp);
      }
    }, [cookieProps]);
    useEffect(() => {
      if (
          typeof window !== 'undefined' &&
          typeof window.FS?.identify === 'function' &&
          cookieProps?.value === 'recorded' &&
          userId &&
          userType
      ) {
        window.FS.identify(userId, {
          userType,
          ...(args?.additionalIdentifyingAttributes || {}),
        });
      }
    }, [userId, userType, args, cookieProps]);
  }
}
